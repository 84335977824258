import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import ContatoForm from "../components/ContatoForm"

export default function inscrever({ location }) {
  const curso = typeof window !== "undefined" ? location.state.curso : "Informática Administrativa"
  return (
    <Layout color="white" breadcrumbs={["Inscrição",curso]} item="2">
        <section class="banner_area">
        <div class="banner_inner d-flex align-items-center">
            <div class="overlay"></div>
            <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                <div class="banner_content text-center">
                    <h2>Inscrição</h2>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <div class="section_gap registration_area">
        <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="row clock_sec clockdiv" id="clockdiv">
              <div class="col-lg-12 align-text-top">
                <h1 class="mb-3">{curso}</h1>
                <p>Temos vagas abertas para o curso. Faça-nos uma visita no endereço abaixo ou converse conosco através do telefone e formulário ao lado.</p>
                <p><b>Rua Guaíra 3170, Guarapuava - PR</b></p>
                <p><b>(42) 3035 3456</b></p>
              </div>
              </div>
          </div>
          <div class="col-lg-6">
            <div class="register_form">
              <h3>Seu curso está a um clique de você</h3>
                <ContatoForm cta="Solicitar Inscrição" curso={curso} />
              </div>
          </div>
        </div>
      </div>
    </div>
       </Layout>
  );
}